<template>
  <v-container class="container--pkmn">
    <v-row>
      <v-col>
        <top-nav></top-nav>
      </v-col>
    </v-row>
    <v-row class="page page--anonymous">
      <v-col class="anonymous__section anonymous__section--content text-left">
        <div class="maintenance-overlay" v-if="isMaintenance"></div>
        <slot name="content"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from '@/helpers'
import introBgImg from '@/assets/intro-bg.jpg'
import TopNav from '@/components/TopNav/TopNav.vue'
export default {
  name: 'AnonymousViewTemplate',
  components: { TopNav },
  data() {
    return {
      introBgImg: introBgImg
    }
  },
  computed: {
    isMaintenance() {
      return helpers.isUnderMaintenance()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.page--anonymous {
  margin-bottom: 200px;
  width: 415px;
  & > .col {
    position: relative;
  }
}

.maintenance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}
</style>
