<template>
  <div dense class="login">
    <h1 class="login__title pkmn-form-title">
      {{ $t('g_log_in') }}
    </h1>
    <p class="text-desc">
      {{ $t('login_text') }}
    </p>

    <v-form
      ref="form"
      :disabled="helpers.isUnderMaintenance()"
      @submit.prevent="verifyIdentifier"
    >
      <v-row>
        <v-col>
          <v-text-field
            ref="input"
            v-model="formData.identifier"
            class="pkmn-input"
            :label="$t('login_identifier')"
            validate-on-blur
            :rules="[rules.identifier]"
            @blur="trimInput"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="text-top">
          <v-switch
            class="pkmn-checkbox signup__agree-box align-top"
            v-model="formData.is_policy_accepted"
            :rules="[rules.legalAccepted]"
            @change="onLegalCheckboxClick"
          >
            <template v-slot:label>
              <div class="text-desc ml-4">
                {{ $t('signup_consent_1') }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://parkman.io/terms"
                      @click.stop
                      @click="onTermsClick"
                      v-on="on"
                    >
                      {{ $t('g_terms_of_use') }}
                    </a>
                  </template>
                  {{ $t('g_open_in_new_window') }}
                </v-tooltip>
                {{ $t('signup_consent_2') }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://parkman.io/privacy-policy"
                      @click.stop
                      @click="onPrivacyPolicyClick"
                      v-on="on"
                    >
                      {{ $t('g_privacy_policy') }}
                    </a>
                  </template>
                  {{ $t('g_open_in_new_window') }}
                </v-tooltip>
                {{ $t('signup_consent_3') }}
              </div>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            class="signup--phone__button pkmn-button pkmn-button--circle"
            color="primary"
            @click="verifyIdentifier"
            :disabled="!formData.is_policy_accepted"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row dense v-if="errorMessage">
      <v-col class="text-right">
        <p class="pkmn-form__error" v-html="errorMessage"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  isValidNumber,
  parsePhoneNumber,
  getPhoneCode
} from 'libphonenumber-js'

import api from '@/helpers/api'
import helpers from '@/helpers'
import rules from '@/helpers/validation'

export default {
  name: 'LoginForm',
  data() {
    return {
      formData: {
        type: '',
        identifier: '',
        is_policy_accepted: false
      },
      rules: rules,
      errorMessage: null,
      helpers: helpers
    }
  },
  // watch: {
  //   'formData.identifier' (after, before) {
  //     // fill the country calling code when user enters a phone number
  //     if (before === '' && !isNaN(after)) {
  //       // remove 0 at the start of the number
  //       const processed = after.startsWith('0')
  //         ? after.substr(1)
  //         : after
  //       // add the country code
  //       this.formData.identifier = this.phoneCountryCode + processed
  //     }
  //   }
  // },
  computed: {
    phoneCountryCode() {
      const country = helpers.getCountryByLanguage(this.$i18n.locale)
      return `+${getPhoneCode(country.toUpperCase())}`
    }
  },
  methods: {
    trimInput() {
      this.formData.identifier = this.formData.identifier.trim()
    },
    async verifyIdentifier() {
      if (!this.$refs.form.validate()) {
        return
      }

      // emit submission to show verification form in parent component
      await this.$store.dispatch('setLoginIdentifier', this.formData.identifier)
      this.$emit('submit', this.formData)

      // identify the type
      const data = {
        language_code: this.$i18n.locale // set the language for verification email / sms
      }

      if (isValidNumber(this.formData.identifier)) {
        this.formData.type = 'phone'
        this.formData.identifier = parsePhoneNumber(
          this.formData.identifier
        ).number
        data.phone = this.formData.identifier
      } else {
        this.formData.type = 'email'
        data.email = this.formData.identifier
      }

      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/passcodes',
        data: data
      }

      try {
        // call endpoint to verify the identifier
        await api.promise(apiSettings)
        // save email to vuex to prefill the input if user wants to edit it later
        await this.$store.dispatch(
          'setLoginIdentifier',
          this.formData.identifier
        )
        this.$emit('submit', this.formData)
      } catch (error) {
        await this.$store.dispatch('setSystemMessage', error)
      }
    },
    onLegalCheckboxClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'check_accept_terms')
    },
    onTermsClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_terms_of_use')
    },
    onPrivacyPolicyClick() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_privacy_policy')
    }
  },
  mounted() {
    helpers.sendTrackingEvent('LOG IN', 'show_business_login_page')
    this.formData.identifier = this.$store.state.loginIdentifier
    this.$refs.input.focus()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
@import '@/style/variables';

.align-top {
  display: flex;
  align-items: flex-start;
}
.login__title {
  margin-bottom: 8px;
}
// .pkmn-checkbox {
//   margin-top: 0;
//   padding-top: 0;
//   display: flex;
//   align-items: flex-top;
// }

.login__btn-forget-pwd {
  color: $color-brand;
}
</style>
