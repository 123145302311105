<template>
  <div dense class="login">
    <h1 class="merchant_accounts__title pkmn-form-title">
      {{ $t('merchant_accounts_title') }}
    </h1>
    <p class="text-desc" v-html="$t('merchant_accounts_text')"></p>
    <v-list>
      <v-list-item
        v-for="account in accounts"
        :key="account.id"
        class="merchant_accounts__option v-list-item"
        @click="selectAccount(account)"
      >
        <tr>
          <td class="account">{{ account.name }}</td>
          <td class="arrow">
            <v-icon> mdi-chevron-right </v-icon>
          </td>
        </tr>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import helpers from '@/helpers'
import rules from '@/helpers/validation'

export default {
  name: 'MerchantAccountsForm',
  props: ['merchants'],
  data() {
    return {
      accounts: this.merchants,
      errorMessage: null,
      rules: rules,
      helpers: helpers
    }
  },
  computed: {
    isTestEnv() {
      return process.env.VUE_APP_ENV === 'test'
    }
  },
  methods: {
    selectAccount(account) {
      this.$store.dispatch('setSelectedMerchantId', account.id)
      this.$emit('submit', account)
    }
  },
  mounted() {
    helpers.sendTrackingEvent(
      'ONBOARDING',
      'business_account_show_phone_number_form'
    )
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.merchant_accounts__title {
  margin-bottom: 8px;
}

.v-list-item {
  padding: 0px;
}
.merchant_accounts__option {
  width: 415px;
  height: 66px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account {
  height: 66px;
  width: 391px;
  color: $text-color-dark;
  vertical-align: middle;
}
.arrow {
  width: 24px;
  vertical-align: middle;
  text-align: right;
}
</style>
