<template>
  <anonymous-template>
    <template v-slot:content>
      <component
        :is="currentView"
        :verificationData="verificationData"
        :merchants="merchants"
        @submit="handleSubmit"
        @editIdentifier="editIdentifier"
      >
      </component>
      <site-footer />
    </template>
  </anonymous-template>
</template>

<script>
// import auth from '@/auth'

import AnonymousTemplate from '@/components/Anonymous/Template.vue'
import LoginForm from '@/components/Login/LoginForm.vue'
import VerificationForm from '@/components/Login/VerificationForm'
import PhoneNumberForm from '@/components/Login/PhoneNumberForm'
import MerchantAccountsForm from '@/components/Login/MerchantAccountsForm.vue'
import SiteFooter from '@/components/SiteFooter'
import merchantHelpers from '@/helpers/merchant'
import auth from '@/auth'

export default {
  name: 'LoginView',
  components: {
    AnonymousTemplate,
    LoginForm,
    VerificationForm,
    MerchantAccountsForm,
    SiteFooter
  },
  data() {
    return {
      currentView: LoginForm,
      verificationData: {
        type: 'email',
        identifier: ''
      },
      merchants: []
    }
  },
  methods: {
    async handleSubmit(data) {
      switch (this.currentView) {
        case LoginForm:
          this.showVerification(data)
          break
        case VerificationForm:
          await this.verifyIdentifier()
          break
        case PhoneNumberForm:
          this.showPhoneVerification(data)
          break
        case MerchantAccountsForm:
          await this.$router.push({
            name: 'Merchant',
            params: { merchantId: data.id }
          })
          break
        default:
          break
      }
    },
    showPhoneVerification(data) {
      this.verificationData = {
        type: 'phone',
        identifier: data.phone
      }
      this.currentView = VerificationForm
    },
    showVerification(data) {
      this.verificationData = data
      // show verification form
      this.currentView = VerificationForm
    },
    async verifyIdentifier() {
      await this.$store.dispatch('setShowOverlay', true)
      const [merchants] = await Promise.all([
        merchantHelpers.fetchAllMerchants(),
        merchantHelpers.fetchCountries()
      ])
      // Could be asynchronous because it doesn't block the user flow
      merchantHelpers.fetchMerchantLogoList()
      this.merchants = merchants

      if (this.merchants.length === 0) {
        await this.$store.dispatch('setSystemMessage', {
          message: "Merchant account doesn't exist!"
        })
        auth.logout()
      } else if (this.merchants.length > 1 && !auth.isParkmanAdmin()) {
        this.currentView = MerchantAccountsForm
      } else {
        await this.$store.dispatch(
          'setSelectedMerchantId',
          this.merchants[0].id
        )
        if (this.merchants.length === 1) {
          await this.$router.push({
            path: `/merchants/${this.merchants[0].id}`
          })
        } else {
          await this.$router.push({ path: '/merchants' })
        }
      }
      await this.$store.dispatch('setShowOverlay', false)
    },
    editIdentifier() {
      // show the identifier input form
      this.currentView = LoginForm
    }
  }
}
</script>
