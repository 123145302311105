<template>
  <v-footer
    app
    class="pkmn-footer"
    :class="{ 'pkmn-footer--logged-in': $route.meta.loggedIn }"
  >
    <v-container class="footer__container">
      <v-row class="footer__sticker d-none d-sm-flex" v-if="showFinnishFlag">
        <v-col class="sticker__wrap" cols="12" sm="10" md="9">
          <img class="fi-flag" src="@/assets/avainlippu.png" />
        </v-col>
      </v-row>
      <v-row class="footer__content">
        <v-col cols="12" sm="4" class="text-sm-left">
          © {{ year }} ParkMan Oy. v{{ version }} build {{ buildtime }}
        </v-col>
        <v-col cols="12" sm="8" class="text-sm-right">
          <div class="footer__info d-sm-inline">{{ $t('contact_phone') }}</div>
          <div class="footer__info d-sm-inline">{{ $t('contact_email') }}</div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  computed: {
    year() {
      const today = new Date()
      return today.getFullYear()
    },
    version() {
      return process.env.VERSION
    },
    buildtime() {
      return document.documentElement.dataset.buildTimestamp
    },
    showFinnishFlag() {
      if (this.$route.meta.loggedIn) {
        return false
      }

      return this.$i18n.locale === 'fi'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
@import '~vuetify/src/styles/settings/_variables';

.pkmn-footer {
  color: #888;
  font-size: 12px;
  font-family: Roboto;
  line-height: 150%;
  background-color: #fff !important;
}

.footer__container {
  padding: 0;
}

.footer__content {
  border-top: 1px solid $color-line;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .pkmn-footer--logged-in {
    position: relative;
    width: unset;
    background: #fff;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .footer__info:not(:first-child) {
    margin-left: 24px;
  }
}

.sticker__wrap {
  text-align: right;
}

.fi-flag {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-right: -16px;
  }
}
</style>
