<template>
  <div class="signup signup--phone">
    <h1 class="signup__title pkmn-form-title">
      {{ title }}
    </h1>
    <p class="text-desc" v-html="descriptionText"></p>

    <v-form ref="form" @submit.prevent="submit">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="codeInput"
            v-model="formData.code"
            maxlength="4"
            class="pkmn-input pkmn-input--verification-code"
            :label="$t('g_code')"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="text-left">
          <a class="pkmn-link-button" @click="editIdentifier">
            {{ editIdentifierText }}
          </a>
          <br />
        </v-col>
        <v-col cols="12" class="text-left">
          <a class="pkmn-link-button" @click="resendCode">
            {{ resendButtonText }}
          </a>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            class="signup--phone__button pkmn-button pkmn-button--circle"
            color="primary"
            @click="submit"
            :disabled="String(this.formData.code).length !== 4"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense v-if="errorMessage">
        <v-col cols="12" class="text-right">
          <p class="pkmn-form__error" v-html="errorMessage"></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment'

import api from '@/helpers/api'
import helpers from '@/helpers'
import auth from '@/auth'
import userHelpers from '@/helpers/user'

export default {
  name: 'VerificationForm',
  props: ['verificationData'], // includes type & identifier. type: accepts phone or email
  data() {
    return {
      formData: {
        code: ''
      },
      errorMessage: null,
      attempt: 1,
      lastResendAttempt: null
    }
  },
  computed: {
    type() {
      return this.verificationData ? this.verificationData.type : ''
    },
    identifier() {
      return this.verificationData ? this.verificationData.identifier : ''
    },
    title() {
      return this.type === 'phone'
        ? this.$t('signup_phone_verify_title')
        : this.$t('signup_email_verify_title')
    },
    descriptionText() {
      return this.type === 'phone'
        ? this.$t('signup_phone_verify_text', { phone: this.identifier })
        : this.$t('signup_email_verify_text', { email: this.identifier })
    },
    editIdentifierText() {
      return this.type === 'phone'
        ? this.$t('signup_phone_verify_back')
        : this.$t('edit_email')
    },
    resendButtonText() {
      return this.type === 'phone'
        ? this.$t('signup_phone_verify_resend')
        : this.$t('signup_email_verify_resend')
    }
  },
  watch: {
    'formData.code'() {
      // automatically submit the form when user enter 4 characters
      if (String(this.formData.code).length === 4) {
        this.submit()
      }
    }
  },
  methods: {
    async submit() {
      // prevent user submit the form consecutively in a short time
      if (!this.$store.state.isPageReady) {
        return
      }
      const response = await auth.signInMerchant({
        email: this.identifier,
        code: this.formData.code
      })
      await userHelpers.fetchAccountRoleOptions()
      if (response) {
        this.$emit('submit')
        this.formData.code = ''
      }
    },
    editIdentifier() {
      this.$emit('editIdentifier', this.type)
    },
    async resendCode() {
      helpers.sendTrackingEvent(
        'ONBOARDING',
        'business_account_resend_phone_verification_code'
      )
      this.attempt++

      const data = {
        language_code: this.$i18n.locale // set the language for verification email / sms
      }
      const messages = {}

      if (this.type === 'phone') {
        data.phone = this.identifier
        messages.success = this.$t('signup_sms_sent')
        messages.multipleAttempt = this.$t('signup_sms_sent_repeat')
      } else {
        data.email = this.identifier
        messages.success = this.$t('signup_email_sent')
        messages.multipleAttempt = this.$t('signup_email_sent_repeat')
      }

      // set rate limter for spamming prevention
      if (
        this.lastResendAttempt !== null &&
        moment().diff(this.lastResendAttempt, 'second') < 60
      ) {
        await this.$store.dispatch('setSystemMessage', messages.multipleAttempt)
        return
      }

      // call backend to resend verification code
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: 'v1/passcodes',
        data: data
      }

      try {
        await api.promise(apiSettings)

        // show sucess message
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: messages.success
        })
      } catch (error) {
        await this.$store.dispatch('setSystemMessage', error)
      } finally {
        this.lastResendAttempt = moment()
      }
    }
  },
  mounted() {
    this.$refs.codeInput.focus()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.signup__title {
  margin-bottom: 8px;
}
</style>
